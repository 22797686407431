import { THEMES } from '@dfl/mui-react-common';
import { grey } from '@mui/material/colors';
import { colors, createTheme, lighten } from '@mui/material';
import { components } from 'settings/components';

export const RED = '#d32f2f';
export const GREEN = '#B8FF80';
export const GRAY = '#646D82';
export const WARNING = colors.deepOrange['500'];

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    formLabel: string;
    link: string;
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }

  interface TypographyVariants {
    dashboardTitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    dashboardTitle?: React.CSSProperties;
    smallTitle?: React.CSSProperties;
    mediumTitle?: React.CSSProperties;
    largeTitle?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    dashboardTitle: true;
    smallTitle: true;
    mediumTitle: true;
    largeTitle: true;
  }
}

// Extend color prop on components
declare module '@mui/material/Chip' {
  export interface ChipPropsColorOverrides {
    opacity: true;
  }
}

export const common = createTheme({
  components: {
    ...components,
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme: { palette } }) => ({
          background: palette.mode === 'light' ? lighten(palette.primary.light, 0.8) : grey[800],

          '.MuiTableCell-root,.MuiTableSortLabel-root': {
            // color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          '.MuiButtonBase-root': {
            minWidth: 20,
            textTransform: 'none',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    h2: {
      fontSize: 16,
    },
    h3: {
      fontSize: 18,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 13,
    },
    caption: {
      fontSize: 12,
    },
    dashboardTitle: {
      fontSize: 22,
      lineHeight: 1.2,
      fontWeight: 'bold',
      // @ts-ignore
      '@media (min-width:600px)': {
        fontSize: 24,
      },
      '@media (min-width:1200px)': {
        fontSize: 26,
      },
    },
    largeTitle: {
      fontSize: 20,
      lineHeight: 1.2,
      fontWeight: 'bold',
      // @ts-ignore
      '@media (min-width:600px)': {
        fontSize: 22,
      },
      '@media (min-width:1200px)': {
        fontSize: 24,
      },
    },
    mediumTitle: {
      fontSize: 18,
      lineHeight: 1.2,
      fontWeight: 'bold',
      // @ts-ignore
      '@media (min-width:600px)': {
        fontSize: 20,
      },
      '@media (min-width:1200px)': {
        fontSize: 22,
      },
    },
    smallTitle: {
      fontSize: 18,
      lineHeight: 1.2,
      fontWeight: 'bold',
      // @ts-ignore
      '@media (min-width:600px)': {
        fontSize: 20,
      },
      '@media (min-width:1200px)': {
        fontSize: 20,
      },
    },
  },
});

const LIGHT = {
  ...common,
  palette: {
    mode: THEMES.LIGHT,
    sidebar: {
      background: '#081a54',
      color: 'background.paper',
      activeColor: 'primary.light',
      active: 'transparent',
    },
    spaceSelector: '#f3f4f9',
    primary: {
      main: '#96E05C',
      light: '#B8FF80',
      dark: '#56C56F',
    },
    opacity: {
      main: '#fdfdfd59',
      contrastText: '#fff',
    },
    grey: {
      ...grey,
      100: '#e8ecf6',
      200: '#F1F3FB',
      300: '#E2E4E7',
    },
    secondary: {
      ...grey,
      main: grey['900'],
      light: grey['400'],
    },
    neutral: {
      main: '#f5f7fa',
    },
    background: {
      default: '#f5f7fa',
      paper: '#FFFFFF',
    },
    formLabel: '#11235b',
    link: '#96E05C',
    text: {
      primary: '#11235b',
      secondary: '#253568',
    },
  },
};

const DARK = {
  ...common,
  palette: {
    mode: THEMES.DARK,
    sidebar: '#111827',
    spaceSelector: '#222b36',
    background: {
      default: '#1e2732',
      paper: '#222b36',
    },
    primary: {
      main: '#90caf9', // '#a4328a',
      light: '#363e48',
    },
    opacity: {
      main: '#fdfdfd59',
      contrastText: '#fff',
    },
    secondary: {
      ...grey,
      main: grey['400'],
      light: grey['800'],
    },
    neutral: {
      main: grey['800'],
    },
    formLabel: '#fff',
    link: '#8ab4f8',
  },
};

export const THEME_SETTING = {
  [THEMES.LIGHT]: LIGHT,
  [THEMES.DARK]: DARK,
};
